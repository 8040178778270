import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { graphql, navigate } from "gatsby"
import CheckIcon from "@material-ui/icons/Check"
import CloseIcon from "@material-ui/icons/Close"
import Parser from "html-react-parser"
import {
  TextField,
  FormControl,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Select,
  IconButton,
  Snackbar,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from "@material-ui/core"
import ReCAPTCHA from "react-google-recaptcha"
// import app components
import { Edges, Button, Pagination } from "components"
import { colors } from "theme"

import {
  transformToGravityFormsFormat,
  submitForm
} from "../../components/react-gravity-forms/utils"

const productsID = 2

const Fullpageform = (props) => {
  const {
    data: {
      page: {
        templateFullpageform: {
          fullPageFormFields: { formId }
        },
        uri
      },
      allGfForm
    }
  } = props

  const formID = parseInt(formId ? formId : 15)
  const gForm = allGfForm.nodes.find((o) => o.formId === formID)
  const gFormSections = gForm.formFields.filter((o) => o.type === "section")

  const gfFormData = {}
  gForm.formFields
    .filter((o) => o.type !== "section")
    .map(
      (o) =>
        (gfFormData[`input_${o.id}`] =
          o.type === "checkbox" ? [] : o.defaultValue)
    )

  const sourceUrl = props?.location?.state?.sourceUrl
    ? props.location.state.sourceUrl
    : uri
  const dealer = props?.location?.state?.dealer
    ? props.location.state.dealer
    : ""

  const [data, setData] = useState({ ...gfFormData })
  const [section, setSection] = useState("products")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({})
  const [success, setSuccess] = useState(null)
  const [captchaValue, setCaptchaValue] = useState(null)
  const recaptchaRef = useRef(null)
  const dialogContentRef = useRef(null)
  // set the hidden field as the current path
  useEffect(() => {
    setData({ ...data, [`input_29`]: sourceUrl, [`input_39`]: dealer })
  }, [uri, setData])

  useEffect(() => {
    typeof window !== "undefined" && window.scrollTo(0, 0)
  }, [section])

  const handleChangeArray = (key, value) => {
    let array = [...data[`input_${key}`]]
    const index = array.indexOf(value)

    if (index !== -1) {
      array.splice(index, 1)
    } else {
      array.push(value)
    }

    // Make sure order of products is always the same
    if (key === productsID) {
      const choices = gForm.formFields.find((o) => o.id === productsID).choices

      array = array.sort((a, b) => {
        return (
          JSON.parse(choices).findIndex((o) => o.value === a) -
          JSON.parse(choices).findIndex((o) => o.value === b)
        )
      })
    }

    setData({ ...data, [`input_${key}`]: array })
  }
  const handleRecaptcha = (value) => {
    setCaptchaValue(value)
  }

  const handleChangeText = (key, value) =>
    setData({ ...data, [`input_${key}`]: value })

  const handleSubmit = async () => {
    if (!captchaValue) {
      alert("Please verify that you are not a robot.")
      return
    }
    setLoading(true)

    const transformedData = transformToGravityFormsFormat(gForm, {
      formData: { ...data }
    })

    try {
      const result = await submitForm(transformedData, gForm.apiURL)
      recaptchaRef.current.reset()

      if (result) {
        const {
          is_valid,
          validation_messages,
          confirmation_type,
          confirmation_message,
          confirmation_redirect
        } = result
        if (is_valid) {
          if (confirmation_type === "redirect") {
            const path = confirmation_redirect.replace(
              process.env.GATSBY_WP,
              ""
            )
            navigate(path)
          } else {
            setSuccess(confirmation_message)
            setSection("success")
          }
        } else {
          console.log(validation_messages)

          setError({ validation_messages })
          console.log(error)
        }
      }

      setLoading(false)
    } catch (e) {
      navigate("/request-quotation/thank-you")
      return
    }
  }

  const checkForErrors = (sectionKey) => {
    const i = gFormSections.findIndex((o) => o.cssClass === sectionKey)
    const activeSection = gFormSections[i]

    let startIndex =
      gForm.formFields.findIndex((p) => p.id === activeSection.id) + 1
    let endIndex =
      i === gFormSections.length - 1
        ? gForm.formFields.length - 1
        : gForm.formFields.findIndex((p) => p.id === gFormSections[i + 1].id) -
          1

    let j
    for (j = startIndex; j < endIndex + 1; j++) {
      const id = gForm.formFields[j].id
      const value = data[`input_${id}`]

      let shouldCheck = true

      const conditionalLogic = JSON.parse(gForm.formFields[j].conditionalLogic)

      if (conditionalLogic?.rules) {
        conditionalLogic.rules.map((o) => {
          if (o.operator === "is") {
            if (
              !data[`input_${o.fieldId}`]
                .map((p) => p.replace(/_/g, " "))
                .includes(o.value.replace(/_/g, " "))
            ) {
              shouldCheck = false
              return
            }
          }
        })
      }

      if (shouldCheck) {
        if (gForm.formFields[j].isRequired) {
          if (typeof value === "string" || value instanceof String) {
            if (value === "") return true
          } else if (value instanceof Array) {
            if (value.length === 0) return true
          }
        }
      }
    }

    return false
  }

  const renderPagination = (s, i) => {
    const activeSections = ["products", ...data[`input_${productsID}`]]

    if (
      data[`input_${productsID}`].includes("Deluxe_Console_Generation_DLX") ||
      data[`input_${productsID}`].includes("Luxury_Sport_RIBs_Venture") ||
      data[`input_${productsID}`].includes(
        "Lightweight_Deluxe_Console_Generation_LTE"
      )
    ) {
      activeSections.push("color-trim")
    }

    activeSections.push("information")

    const sectionIndex = activeSections.findIndex((str) => str === s.cssClass)

    let prev, next

    if (sectionIndex !== -1) {
      if (sectionIndex === 0) {
        prev = null
        next =
          data[`input_${productsID}`].length > 0
            ? activeSections[sectionIndex + 1]
            : null
      } else if (sectionIndex === activeSections.length - 1) {
        prev = activeSections[sectionIndex - 1]
        next = null
      } else {
        prev = activeSections[sectionIndex - 1]
        next = activeSections[sectionIndex + 1]
      }

      const hasErrors = checkForErrors(activeSections[sectionIndex])

      const handlePrev = (prev) => {
        setSection(prev)
        return dialogContentRef?.current?.children[2].children[0].scrollTo(0, 0)
      }

      const handleNext = (next) => {
        next ? setSection(next) : handleSubmit()
        return dialogContentRef?.current?.children[2].children[0].scrollTo(0, 0)
      }

      return (
        <>
          {data[`input_${productsID}`].length > 0 && (
            <Dots>
              {activeSections.map((s, i) => (
                <Dot key={i} active={i === sectionIndex} />
              ))}
            </Dots>
          )}

          {sectionIndex === activeSections.length - 1 && (
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={`${process.env.GATSBY_RECAPTCHA}`}
              onChange={handleRecaptcha}
              style={{ margin: "10px 0" }}
            />
          )}
          <Pagination
            onPrev={() => prev && handlePrev(prev)}
            onNext={() => handleNext(next)}
            prevHidden={prev === null}
            nextText={
              sectionIndex === activeSections.length - 1 ? "Submit" : "Next"
            }
            nextDisabled={data[`input_${productsID}`].length === 0 || hasErrors}
          />
          {sectionIndex === activeSections.length - 1 && (
            <div>* Indicates field is mandatory. </div>
          )}
        </>
      )
    }

    return null
  }

  const renderSections = () => {
    let elements = []

    gFormSections.map((o, i) => {
      let startIndex = gForm.formFields.findIndex((p) => p.id === o.id) + 1
      let endIndex =
        i === gFormSections.length - 1
          ? gForm.formFields.length - 1
          : gForm.formFields.findIndex(
              (p) => p.id === gFormSections[i + 1].id
            ) - 1

      let subElements = []

      let j
      for (j = startIndex; j < endIndex + 1; j++) {
        const gfField = gForm.formFields[j]

        let element

        let shouldRender = true

        const conditionalLogic = JSON.parse(gfField.conditionalLogic)

        if (conditionalLogic?.rules) {
          conditionalLogic.rules.forEach((o) => {
            // Assuming `data` is your form data state and `o` is your condition object
            let fieldValue = data[`input_${o.fieldId}`]

            if (o.operator === "is") {
              let shouldDisplay = false
              // Check if the field is in an initial state and decide whether it should display
              if (Array.isArray(fieldValue) && fieldValue.length === 0) {
                // If it's an array and empty, decide based on your form logic
                shouldDisplay = true
              } else if (fieldValue === "") {
                // If it's a string and empty, decide based on your form logic
                shouldDisplay = true
              } else {
                // Regular condition check
                shouldDisplay = fieldValue.includes(o.value)
              }

              if (!shouldDisplay) {
                shouldRender = false
              }
            }
          })
        }
        const isRequiredAsterix = gfField.isRequired ? "* " : ""

        if (shouldRender) {
          if (
            gfField.type === "number" ||
            gfField.type === "text" ||
            gfField.type === "email" ||
            gfField.type === "phone"
          ) {
            element = (
              <>
                <Label children={isRequiredAsterix + gfField.label} />
                <TextField
                  value={data[`input_${gfField.id}`]}
                  onChange={(e) => handleChangeText(gfField.id, e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  type={gfField.type}
                  required={gfField.isRequired}
                />
              </>
            )
          } else if (gfField.type === "textarea") {
            element = (
              <>
                <Label children={gfField.label} />
                <TextField
                  value={data[`input_${gfField.id}`]}
                  onChange={(e) => handleChangeText(gfField.id, e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  type={gfField.type}
                  multiline
                  rows="6"
                  required={gfField.isRequired}
                />
              </>
            )
          } else if (gfField.type === "checkbox") {
            const checkboxes = JSON.parse(gfField.choices).map((o, j) => {
              return (
                <FormControlLabel
                  key={j}
                  control={
                    <Checkbox
                      checked={data[`input_${gfField.id}`].includes(o.value)}
                      onChange={() => handleChangeArray(gfField.id, o.value)}
                      value={o.value}
                      color="primary"
                      checkedIcon={<CheckIcon />}
                    />
                  }
                  label={Parser(o.text)}
                  required={gfField.isRequired}
                />
              )
            })

            element = (
              <FormControl required={gfField.isRequired}>
                {gfField.labelPlacement !== "hidden_label" && (
                  <FormLabel component="legend" children={gfField.label} />
                )}
                {gfField.cssClass.includes("images") ? (
                  <StyledFormGroup>{checkboxes}</StyledFormGroup>
                ) : (
                  checkboxes
                )}
              </FormControl>
            )
          } else if (gfField.type === "select") {
            const menuItems = JSON.parse(gfField.choices).map((o) => (
              <option key={o.value} value={o.value} children={o.text} />
            ))

            element = (
              <>
                <Label children={gfField.label} />
                <FormControl
                  fullWidth
                  variant="outlined"
                  required={gfField.isRequired}
                >
                  <Select
                    native
                    value={data[`input_${gfField.id}`]}
                    onChange={(e) =>
                      handleChangeText(gfField.id, e.target.value)
                    }
                    children={menuItems}
                  />
                </FormControl>
              </>
            )
          }

          subElements.push(
            <Element
              key={j}
              className={`${gfField.cssClass} size-${gfField.size}`}
            >
              {element}
            </Element>
          )
        }
      }

      elements.push(
        <Section key={i} active={o.cssClass.includes(section)}>
          <StyledDialogTitle>
            <CloseBar>
              <CloseIconContainer onClick={() => navigate("/")}>
                <CloseIcon />
              </CloseIconContainer>
            </CloseBar>
          </StyledDialogTitle>

          <DialogContent>
            <Edges size="lg">
              {i === 0 && <Title variant="h2" children="Request a Quote" />}

              <Headline>{o.label}</Headline>

              <SectionContent>
                <Elements>{subElements}</Elements>
              </SectionContent>
            </Edges>
          </DialogContent>

          <StyledDialogActions>
            <Edges size="lg">{renderPagination(gFormSections[i], i)}</Edges>
          </StyledDialogActions>
        </Section>
      )
    })

    elements.push(
      <Section key="success" active={section === "success"}>
        <StyledDialogTitle>
          <CloseBar>
            <CloseIconContainer onClick={() => navigate("/")}>
              <CloseIcon />
            </CloseIconContainer>
          </CloseBar>
        </StyledDialogTitle>

        <DialogContent>
          <Edges size="lg">
            <Headline id="fullpage-form-success">Thank You</Headline>

            <SectionContent>
              <Elements>
                {success && Parser(success)}
                <Button
                  onClick={() => navigate("/")}
                  children={`Back to Homepage`}
                />
              </Elements>
            </SectionContent>
          </Edges>
        </DialogContent>
      </Section>
    )

    return <div children={elements} />
  }

  return (
    <>
      <Dialog
        open={true}
        onClose={() => navigate("/")}
        fullScreen
        scroll="paper"
        ref={dialogContentRef}
        transitionDuration={0}
      >
        {renderSections()}

        {loading && (
          <Loader>
            <CircularProgress color="primary" />
          </Loader>
        )}
        {error?.validation_messages &&
          Object.entries(error.validation_messages).map(
            ([key, value], index) => (
              <Snackbar
                key={key}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
                style={{
                  bottom: `${50 + index * 60}px` // Adjust each Snackbar to be 60px higher than the previous one
                }}
                open={true}
                autoHideDuration={4000}
                onClose={() => setError(null)}
                message={value}
                action={
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => setError(null)}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                }
              />
            )
          )}
      </Dialog>

      <Placeholder>
        <CircularProgress />
      </Placeholder>
    </>
  )
}

const Placeholder = styled.div`
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CloseBar = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
`

const CloseIconContainer = styled(IconButton)`
  && {
    cursor: pointer;
    transform: translate(-15px, 10px);

    svg {
      width: 30px;
      height: 30px;
    }
  }
`

const Title = styled.h2`
  font-weight: 500;
  font-size: 38px;
  line-height: 42px;
  color: ${colors.text.dark};
  margin: -15px 0px 10px;
`

const Headline = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  text-transform: none;
  color: ${colors.secondary};
  margin-top: 10px;
  margin-bottom: 20px;
`

const Section = styled.section`
  background: #fff;
  ${(props) =>
    !props.active &&
    `
    display: none;
  `}

  .MuiDialogContent-root {
    padding-bottom: 0px;
  }
`

const StyledDialogTitle = styled(DialogTitle)`
  && {
    position: relative;
    padding: 0;
  }
`

const SectionContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const Elements = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  .MuiFormControl-root {
    width: 100%;
  }

  .gform_confirmation_wrapper {
    width: 100%;
    margin-top: 10vw;
    margin-bottom: 40px;

    h1,
    h2 {
      text-transform: none;
    }
  }
`

const Element = styled.div`
  && {
    width: 100%;
    margin-bottom: 20px;

    &&.full-width {
      width: 100%;
    }

    &&.textfield {
      @media (min-width: 600px) {
        width: calc(50% - 10px);
      }
    }

    &&.checkbox-reset {
      width: 100%;
    }

    &.newsletter {
      width: 100%;

      .MuiFormControlLabel-root {
        width: 100%;
      }
    }

    &&.text,
    &&.newsletter {
      .MuiIconButton-root {
        width: 24px;
        height: 24px;
        opacity: 1;
        background: transparent;
        color: ${colors.text.dark};

        svg {
          width: 24px;
          height: 24px;
        }
      }

      .MuiTypography-root {
        background: transparent;
        display: block;
        height: auto;
        padding: 0 10px 0 30px;
      }

      .MuiFormLabel-root {
        margin-top: 20px;
      }

      .MuiFormControlLabel-root {
        margin: 0 0 30px 0;
      }
    }

    .MuiOutlinedInput-input {
      padding: 12.5px 14px;
    }

    .MuiInputBase-root {
      border-radius: 0;
    }

    .MuiSelect-root,
    .MuiOutlinedInput-root {
      border-radius: 0;
    }

    .MuiFormLabel-root {
      margin-bottom: 20px;
      font-size: 21px;
      line-height: 28px;
      letter-spacing: -0.2px;
      color: ${colors.text.dark};
    }

    .MuiFormLabel-asterisk {
      color: red;
    }
  }
`

const StyledFormGroup = styled(FormGroup)`
  && {
    flex-direction: row;
    width: 100%;

    .MuiFormControl-root {
      width: 100%;
    }

    .MuiFormControlLabel-root {
      align-items: flex-start;
      display: inline-block;
      margin: 0 0 60px 0;
      position: relative;
      width: 100%;

      @media (min-width: 800px) {
        width: calc(25% - 20px);
        margin: 0 20px 60px 0;
      }

      @media ((min-width: 440px) and (max-width: 799px)) {
        width: calc(33% - 20px);
        margin: 0 20px 60px 0;
      }

      .Mui-checked {
        .MuiIconButton-label {
          svg {
            width: 50px;
            height: 50px;
          }

          &:after {
            content: "";
          }
        }
      }

      .MuiCheckbox-root {
        position: absolute;
        z-index: 5;
        left: 0;
        top: 0;
        width: 100%;
        height: 175px;
        opacity: 0;
        background: rgba(81, 234, 255, 0.6);
        color: #fff;
        border-radius: 0;
      }

      .Mui-checked {
        opacity: 1;
      }
    }

    .MuiTypography-root {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      background: #f9f5ee;
      height: 175px;
      width: 100%;
      padding: 10px;

      > img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        margin: 0;
      }

      > div {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        > img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      h3 {
        width: 100%;
        position: absolute;
        left: 0;
        top: calc(100% - 10px);
        font-size: 16px;
        line-height: 22px;
        color: ${colors.text.dark};
      }
    }
  }
`

const Label = styled.label`
  display: block;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 6px;
  color: #606475;
`

const StyledDialogActions = styled(DialogActions)`
  && {
    padding: 0 24px;
  }
`

const Dots = styled.div`
  position: fixed;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 8;
  width: 12px;

  @media (max-width: 959px) {
    display: none;
  }
`

const Dot = styled.div`
  width: 6px;
  height: 6px;
  margin: 16px 0;
  border-radius: 50%;
  background: ${(props) => (props.active ? "#74B02C" : "#695E49")};
`

const Loader = styled.div`
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
`

export default Fullpageform

export const CollectionQuery = graphql`
  query FullpageformTemplate($id: String!) {
    page: wpPage(id: { eq: $id }) {
      templateFullpageform {
        fullPageFormFields {
          formId
        }
      }
      uri
    }
    allGfForm {
      nodes {
        formId
        title
        slug
        apiURL
        labelPlacement
        descriptionPlacement
        formFields {
          id
          label
          labelPlacement
          isRequired
          checkboxLabel
          conditionalLogic
          description
          descriptionPlacement
          type
          choices
          content
          errorMessage
          inputMaskValue
          visibility
          cssClass
          placeholder
          size
          defaultValue
          maxLength
        }
        button {
          text
        }
        confirmations {
          id
          name
          type
          message
        }
      }
    }
  }
`
